import { cn } from "@fscrypto/ui";
import { Slot } from "@radix-ui/react-slot";
import React from "react";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
}

const GrailButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          "inline-flex h-10 items-center justify-center rounded-full px-4 text-sm font-medium text-white",
          !props.disabled && "outline-fuchsia-400/15 bg-gradient-to-r from-[#C03BFF] to-[#5E78FF] outline outline-8",
          props.disabled && "cursor-not-allowed bg-white/20 opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      ></Comp>
    );
  },
);

GrailButton.displayName = "GrailButton";

export { GrailButton };
